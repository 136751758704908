import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  AcademicSession,
  AcademicSessionCreateInput,
  Classroom,
  ClassroomCreateInput,
  Organization,
  OrganizationCreateInput,
  RosteringGrade,
} from './organization.model';
import { ApiGatewayService } from '@wam/authentication';
import { Store } from '@ngrx/store';
import { State } from './state/organizations.state';
import { map, mergeMap } from 'rxjs/operators';
import { isEmpty, omitBy } from 'lodash-es';
import { DateTransformer } from '@app/shared/date-transformer.data';
import { WithAuthService } from '@app/shared/services/with-auth.service';

@Injectable({
  providedIn: 'root',
})
export class OrganizationsDataService extends WithAuthService {
  constructor(
    protected store: Store<State>,
    private apiGatewayService: ApiGatewayService,
  ) {
    super(store);
  }

  getOrganization(customerId: string, organizationId: string): Observable<Organization> {
    return this.apiGatewayService
      .simpleGet<{
        org: Organization;
      }>(`rostering/v2/rostering/source/${customerId}/orgs/${organizationId}`)
      .pipe(map(({ org }) => org));
  }

  getOrganizations(): Observable<Organization[]> {
    return this.withUser().pipe(
      mergeMap(({ organization }) =>
        this.apiGatewayService.simpleGet<{ orgs: Organization[] }>(
          `rostering/v2/rostering/source/${organization}/orgs?limit=1000&offset=0&sort=name`,
        ),
      ),
      map(({ orgs }) => orgs),
    );
  }

  getClass(classId: string, schoolId: string, organizationId: string): Observable<Classroom> {
    return this.apiGatewayService
      .simpleGet<{
        class: Classroom;
      }>(`rostering/v2/rostering/source/${organizationId}/orgs/${schoolId}/classes/${classId}`)
      .pipe(map((response) => response.class));
  }

  getClasses(schoolId: string): Observable<Classroom[]> {
    return this.withUser().pipe(
      mergeMap(({ organization }) =>
        this.apiGatewayService.simpleGet<{ classes: Classroom[] }>(
          `rostering/v2/rostering/source/${organization}/orgs/${schoolId}/classes?sort=title`,
        ),
      ),
      map(({ classes }) => classes),
    );
  }

  getAcademicSession(): Observable<AcademicSession> {
    return this.withUser().pipe(
      mergeMap(({ organization }) =>
        this.apiGatewayService.simpleGet<{ academicSessions: AcademicSession[] }>(
          `rostering/v2/rostering/source/${organization}/orgs/${organization}/academicSessions`,
        ),
      ),
      map(({ academicSessions }) => academicSessions?.[0]),
      map((academicSession: AcademicSession) => {
        if (academicSession) {
          academicSession.sDate = DateTransformer.transform(academicSession.startDate);
          academicSession.eDate = DateTransformer.transform(academicSession.endDate);
        }
        return academicSession;
      }),
    );
  }

  createOrganization(organizationCreateInput: OrganizationCreateInput): Observable<Organization> {
    return this.withUser().pipe(
      mergeMap(({ organization }) =>
        this.apiGatewayService.simplePost<{ org: Organization }>(
          `rostering/v2/rostering/source/${organization}/orgs`,
          omitBy(organizationCreateInput, isEmpty),
        ),
      ),
      map(({ org }) => org),
    );
  }

  updateOrganization(
    sourceId: string,
    organizationUpdateInput: OrganizationCreateInput,
  ): Observable<Organization> {
    return this.withUser().pipe(
      mergeMap(({ organization }) =>
        this.apiGatewayService.simplePut<{ org: Organization }>(
          `rostering/v2/rostering/source/${organization}/orgs/${sourceId}`,
          omitBy(organizationUpdateInput, isEmpty),
        ),
      ),
      map(({ org }) => org),
    );
  }

  deleteOrganization(sourceId: string): Observable<void> {
    return this.withUser().pipe(
      mergeMap(({ organization }) =>
        this.apiGatewayService.delete<void>(
          `rostering/v2/rostering/source/${organization}/orgs/${sourceId}`,
        ),
      ),
    );
  }

  createAcademicSession(
    sessionCreateInput: AcademicSessionCreateInput,
  ): Observable<AcademicSession> {
    return this.withUser().pipe(
      mergeMap(({ organization }) =>
        this.apiGatewayService.simplePost<{ academicSession: AcademicSession }>(
          `rostering/v2/rostering/source/${organization}/orgs/${organization}/academicSessions`,
          sessionCreateInput,
        ),
      ),
      map(({ academicSession }) => academicSession),
    );
  }

  updateAcademicSession(
    sourceId: string,
    sessionUpdateInput: AcademicSessionCreateInput,
  ): Observable<AcademicSession> {
    return this.withUser().pipe(
      mergeMap(({ organization }) =>
        this.apiGatewayService.simplePut<{ academicSession: AcademicSession }>(
          `rostering/v2/rostering/source/${organization}/orgs/${organization}/academicSessions/${sourceId}`,
          sessionUpdateInput,
        ),
      ),
      map(({ academicSession }) => academicSession),
    );
  }

  createClassroom(
    schoolSourcedId: string,
    classroomCreateInput: ClassroomCreateInput,
  ): Observable<Classroom> {
    return this.withUser().pipe(
      mergeMap(({ organization }) =>
        this.apiGatewayService.simplePost<{ class: Classroom }>(
          `rostering/v2/rostering/source/${organization}/orgs/${schoolSourcedId}/classes`,
          classroomCreateInput,
        ),
      ),
      map((response) => response.class),
    );
  }

  updateClassroom(
    sourcedId: string,
    schoolSourcedId: string,
    classroomUpdateInput: ClassroomCreateInput,
  ): Observable<Classroom> {
    return this.withUser().pipe(
      mergeMap(({ organization }) =>
        this.apiGatewayService.simplePut<{ class: Classroom }>(
          `rostering/v2/rostering/source/${organization}/orgs/${schoolSourcedId}/classes/${sourcedId}`,
          classroomUpdateInput,
        ),
      ),
      map((response) => response.class),
    );
  }

  deleteClassroom(sourcedId: string, schoolSourcedId: string): Observable<void> {
    return this.withUser().pipe(
      mergeMap(({ organization }) =>
        this.apiGatewayService.delete<void>(
          `rostering/v2/rostering/source/${organization}/orgs/${schoolSourcedId}/classes/${sourcedId}`,
        ),
      ),
    );
  }

  getGrades(): Observable<RosteringGrade[]> {
    return this.apiGatewayService
      .simpleGet<{ grades: RosteringGrade[] }>('rostering/v2/rostering/static/grades')
      .pipe(map(({ grades }) => grades));
  }

  getTimezones(): Observable<string[]> {
    return this.apiGatewayService
      .simpleGet<{
        timezones: { key: number; value: string }[];
      }>('rostering/v2/rostering/static/timezones')
      .pipe(map(({ timezones }) => timezones.map((timezone) => timezone.value)));
  }
}
